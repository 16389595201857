import React, { useState, useEffect } from "react";

import DemoForm from "../components/Demo/DemoForm";

function FloatingButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  });

  return (
    <div className="group fixed bottom-0 right-0 p-12  flex items-end justify-end w-36 h-36 ">
      <div
        class="xs:text-[12px] lg:text-[16px] text-white text-center font-bold shadow-lg shadow-green-900 flex items-center justify-center py-8 px-2 rounded-full bg-green-500 leading-none z-50 absolute cursor-pointer hover:-translate-1 hover:scale-110 "
        onClick={openModal}
      >
        Connect Us
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-10 h-10 group-hover:scale-125  transition-all duration-[0.6s]"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
          />
        </svg> */}
      </div>
      {isModalOpen && <DemoForm isOpen={isModalOpen} isClose={closeModal} />}
    </div>
  );
}

export default FloatingButton;
